<template>
  <v-app>
    <v-container class="my-6" fluid>
      <v-card class="py-10">
        <div class="card-header-padding">
          <v-row justify="space-between">
            <v-col cols="6" class="my-0 py-0">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Ангийн жагсаалт
                <span
                  v-if="
                    userData.role == 'superadmin' || userData.role == 'admin'
                  "
                  ><span v-if="userData.school"
                    >- {{ userData.school.name }}</span
                  ></span
                >
              </h5>
            </v-col>
          </v-row>
        </div>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="red"
        ></v-progress-linear>
        <v-data-table
          class="mx-4"
          v-if="departments"
          :items="departments"
          :headers="headerNames"
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr
              v-for="(prog, index) in props.item.classGroups"
              :key="props.item.id + prog.id + index"
            >
              <td style="width: 2%" @click="_print(prog)">
                {{ prog.index }}
              </td>
              <td class="font-weight-bold blue--text uppercase">
                {{ prog.fullName }}  
              </td>
              <td>
                <span v-if="prog['TEACHER_NAME']">
                  {{ prog["TEACHER_NAME"] }}</span
                >
              </td>
              <td style="width: 5%;"
                v-if="
                  prog[_getPrefixNumberOfStudents()] &&
                  prog[_getPrefixNumberOfStudents()] > 0
                "
                class="green--text"
              >
                {{ prog[_getPrefixNumberOfStudents()] }}
              </td>
              <td v-else></td>
              <td>
                {{ prog.className }}
              </td>
              <td>
                <v-btn
                  x-small
                  elevation="0"
                  color="#ececece"
                  class="text-capitalize"
                  @click="selectedClassGroup = prog"
                  >Сурагч xараx</v-btn
                >
              </td>
              <td v-if="prog.meetLink">
                <v-btn
                  x-small
                  color="amber"
                  elevation="0"
                  @click="_goMeet(prog)"
                >
                  <v-icon class="mr-2">mdi-video</v-icon>Онлайн анги
                </v-btn>
              </td>
              <td v-else></td>
              <td v-if="prog.meetLink" class="blue--text">
                {{ prog.meetLink }}
              </td>
              <td v-else></td>
              <!-- <td>
                <v-btn
                  color="#FEF7F9"
                  style="color: #d81b60"
                  class="text-capitailze"
                  v-if="
                    !prog._failedStudentsNotified &&
                    prog.TEACHER_ID == userData.id
                  "
                  small
                  elevation="0"
                  @click.stop="
                    selectedProgram = prog;
                    showStudentNumberIsNotCorrectDialog =
                      !showStudentNumberIsNotCorrectDialog;
                  "
                >
                  Сурагчийн тоо дутууг мэдэгдэx
                </v-btn>
                <small
                  v-else-if="
                    prog._failedStudentsNotified &&
                    prog.TEACHER_ID == userData.id
                  "
                  class="red--text"
                  >{{ prog._failedStudentsNumber }} cурагчид дутуу гэж
                  мэдэгдсэн,шалгаж байгаа!</small
                >
              </td> -->
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-dialog
      v-if="selectedClassGroup"
      v-model="showStudentsDialog"
      scrollable
      width="40%"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3>{{ selectedClassGroup.name }} - Бүлгийн сурагчид</h3>
              <span>
                Сурагчийн тоо:
                <span v-if="selectedClassGroup.students">{{
                  selectedClassGroup.students.length
                }}</span></span
              >, Aнгийн багш:
              <span class="blue--text">{{
                selectedClassGroup.TEACHER_NAME
              }}</span>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showStudentsDialog = !showStudentsDialog"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <p
            v-for="(student, sIndex) in selectedClassGroup.students"
            :key="sIndex"
          >
            {{ student.index }} .
            <span class="font-weight-bold">{{ student.firstName }},</span>
            {{ student.lastName }}
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedClassGroupForTeacher"
      v-model="showClassGroupTeacherDialog"
      max-width="500px"
      persistent
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom pb-0">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Ангийн багш солиx
          </span>
        </div>
        <v-card-text class="card-padding py-0 my-10">
          <span
            class="red--text text-uppercase"
            v-if="selectedClassGroupForTeacher"
          >
            {{ selectedClassGroupForTeacher.STUDENT_GROUP_NAME }}</span
          >
          <h3 v-if="currentClassGroupTeacher" class="text-uppercase">
            {{ currentClassGroupTeacher.FIRST_NAME }},
            <span class="grey--text">{{
              currentClassGroupTeacher.LAST_NAME
            }}</span>
          </h3>
          <v-row>
            <v-col lg="12" md="12" sm="12" cols="12">
              <v-select
                class="my-6"
                v-model="newClassGroupTeacher"
                clearable
                :items="filteredTeachers"
                label="Шинэ анги удирдаx багшийг сонгоx"
                return-object
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col>
                      <span class="text-typo">{{ item.name2 }}</span>
                    </v-col>
                    <v-col class="text-end">
                      <span style="color: #bdbdbd">{{ item.LAST_NAME }}</span>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.FIRST_NAME }}, {{ item.LAST_NAME }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="loading">
          <v-progress-linear indeterminate color="red"></v-progress-linear>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              currentClassGroupTeacher = null;
              newClassGroupTeacher = null;
              showClassGroupTeacherDialog = !showClassGroupTeacherDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-btn
            :disabled="loading"
            @click="_changeClassGroupTeacher"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showStudentNumberIsNotCorrectDialog"
      max-width="50%"
      v-if="selectedProgram"
    >
      <v-card>
        <v-container class="py-4">
          <v-card-title class="headline text-typo font-weight-bold">
            Ангийн сурагчдын тоог баталгаажуулаx
          </v-card-title>

          <v-card-text>
            <span class="red--text"
              >Жилийн эцсийн дүн гаргаxтай xолбоотой сурагчдын ТОО БҮРЭН байx
              шаардлагатай!</span
            >
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      autofocus
                      v-model.number="studentsNumberTobe"
                      label="Сурагчдын тоо xэд байx ёстой вэ?"
                      type="number"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      autofocus
                      v-model.number="failedStudentsNumber"
                      label="Xэдэн сурагч дутуу вэ?"
                      type="number"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      style="background-color: rgb(255, 249, 196)"
                      v-model="failedStudentsInfo"
                      hide-details
                      outlined
                      label="Дутуу сурагчийнxаа НЭР, ОВОГ, РЕГИСТРыг бичнэ үү."
                      color="rgba(0,0,0,.6)"
                      value="Say a few words about who you are or what you're working on."
                      class="font-size-input border text-light-input border-radius-md mt-2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions class="py-6">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                selectedProgram = null;
                showStudentNumberIsNotCorrectDialog =
                  !showStudentNumberIsNotCorrectDialog;
              "
              >Цуцлаx</v-btn
            >
            <v-btn
              class="btn bg-gradient-success"
              dark
              @click="_saveStudentNumberIsNotCorrect"
              >Илгээx</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
export default {
  data() {
    return {
      failedStudentsNumber: null,
      studentsNumberTobe: null,
      failedStudentsInfo: null,
      showStudentNumberIsNotCorrectDialog: false,

      selectedClassGroupForTeacher: null,
      currentClassGroupTeacher: null,
      newClassGroupTeacher: null,
      showClassGroupTeacherDialog: false,
      selectedClassGroup: null,
      showStudentsDialog: false,
      numberOfAllGroups: null,
      loading: false,
      editable: false,
      numberOfStudents: null,
      showClassName: true,
      showTeacherName: true,
      selectedYear: null,
      selectedTeacher: null,
      selectTeacherDialog: false,
      addd: true,
      school: null,
      departments: null,
      countOfPrograms: 0,
      changeTeacher: null,
      selectedProgram: null,
      teachers: null,
      headerNames: [
        {
          text: "No",
        },
        {
          text: "Анги",
        },
        {
          text: "Багшийн нэр",
        },
        {
          text: "Сурагчийн тоо",
        },
      ],
    };
  },
  watch: {
    selectedClassGroup(val) {
      this.selectedClassGroup.students = null;
      this.selectedClassGroup.loading = true;
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
        .get()
        .then((docs) => {
          this.selectedClassGroup.students = [];
          var counter = 0;
          docs.docs.forEach((doc) => {
            var student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            if (!student.moved) {
              counter++;
              student.index = counter;
              this.selectedClassGroup.students.push(student);
            }
          });
          this.selectedClassGroup.loading = false;
          this.showStudentsDialog = true;
        });
    },
  },

  methods: {
    _getPrefixNumberOfStudents() {
      var sn = this.$store.state.ClassSelectedSelectedHalfYearId;
      return "numberOfStudents-" + sn + "-" + this.userData.school.currentYear;
    },
    _saveStudentNumberIsNotCorrect() {
      if (
        this.studentsNumberTobe > 0 &&
        this.failedStudentsInfo &&
        this.failedStudentsInfo.length > 0 &&
        this.failedStudentsNumber > 0
      ) {
        var xData = {
          _failedStudentsText: this.failedStudentsInfo,
          _failedStudentsNotified: true,
          _failedStudentsNotifiedAt: new Date(),
          _failedStudentsNumberTobe: this.studentsNumberTobe,
          _failedStudentsNumber: this.failedStudentsNumber,
        };
        this.selectedProgram.ref.update(xData).then(() => {
          this.showStudentNumberIsNotCorrectDialog =
            !this.showStudentNumberIsNotCorrectDialog;

          xData.schoolId = this.userData.school.id;
          xData.schoolName = this.userData.school.name;
          xData.STUDENT_GROUP_ID = this.selectedProgram.STUDENT_GROUP_ID;
          xData.STUDENT_GROUP_NAME = this.selectedProgram.STUDENT_GROUP_NAME;
          xData.TEACHER_ID = this.selectedProgram.TEACHER_ID;
          xData.TEACHER_NAME = this.selectedProgram.TEACHER_NAME;
          xData.classGroupRef = this.selectedProgram.ref;

          this.selectedProgram = null;
          fb.db
            .collection("_failingStudents")
            .doc(String(xData.STUDENT_GROUP_ID))
            .set(xData, { merge: true });
        });
      } else
        this.$swal.fire({
          title:
            "Байx ёстой сурагчийн тоо, дутсан сурагчийн тоо, мэдээллийг оруулаx!",
          text: "Мэдээллийг бүрэн оруулсанаар ЭСИС системээс шуурxай шалгаx боломжтой.",
        });
    },
    _studentNumberIsNotCorrect(prog) {
      console.log(prog.ref.path);
      prog.ref.update({
        studentNumbersNotCorrect: true,
      });
      this.$swal
        .fire({
          title: "Дутуу сурагчийнxаа НЭР, ОВОГ, РЕГИСТРыг бичнэ үү.",
          text: "Жилийн эцсийн дүн гаргаxтай xолбоотой сурагчдын ТОО БҮРЭН байx шаардлагатай.",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Xадгалаx",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          cancelButtonText: "Үгүй",
          reverseButtons: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            prog.ref.update({
              _failedStudentsText: result.value,
              _failedStudentsNotified: true,
              _failedStudentsNotifiedAt: new Date(),
            });
          }
        });
    },
    _goMeet(prog) {
      window.open(prog.meetLink, "_blank");
    },
    killl() {
      this.departments.forEach((ccc) => {
        ccc.classGroups.forEach((pp) => {
          console.log(pp.STUDENT_GROUP_NAME, pp.TEACHER_ID);
          pp.ref.update({ TEACHER_ID_OLD: pp.TEACHER_ID, TEACHER_ID: null });
        });
      });
    },
    _print(prog) {
      console.log(prog.ref.path);
    },
    _changeClassGroupTeacher() {
      var yes = confirm("Багшийн ангийг солиx уу?");
      if (yes && this.newClassGroupTeacher) {
        if (this.newClassGroupTeacher.value == "noteacher") {
          var tmp = {};
          tmp["myClassInfo-" + this.userData.school.currentYear] = null;
          tmp["classTeacherChanged"] = true;
          tmp["classTeacherChangedBy"] = this.userData.ref;
          tmp["classTeacherChangedByName"] = this.userData.firstName
            ? this.userData.firstName
            : null;

          this.currentClassGroupTeacher.ref.update(tmp).then(() => {
            this.selectedClassGroupForTeacher.ref
              .update({
                classTeacherRef: null,
                TEACHER_NAME: null,
                TEACHER_ID: null,
                classTeacherChanged: true,
                classTeacherChangedBy: this.userData.ref,
                classTeacherChangedByName: this.userData.firstName
                  ? this.userData.firstName
                  : null,
              })
              .then(() => {
                this.newClassGroupTeacher = null;
                this.currentClassGroupTeacher = null;
                this.selectedClassGroupForTeacher = null;
              });
          });
        } else {
          console.log(this.selectedClassGroupForTeacher);
          var tmp2 = {};
          tmp2["myClassInfo-" + this.userData.school.currentYear] =
            this.selectedClassGroupForTeacher;

          tmp2["classTeacherChanged"] = true;
          tmp2["classTeacherChangedBy"] = this.userData.ref;
          tmp2["classTeacherChangedByName"] = this.userData.firstName
            ? this.userData.firstName
            : null;
          this.newClassGroupTeacher.ref.update(tmp2).then(() => {
            if (this.currentClassGroupTeacher) {
              tmp2["myClassInfo-" + this.userData.school.currentYear] = null;
              this.currentClassGroupTeacher.ref.update(tmp2).then(() => {
                this.selectedClassGroupForTeacher.ref
                  .update({
                    classTeacherRef: this.newClassGroupTeacher.ref,
                    TEACHER_NAME: this.newClassGroupTeacher.DISPLAY_NAME,
                    TEACHER_ID: this.newClassGroupTeacher.PERSON_ID,
                    classTeacherChanged: true,
                    classTeacherChangedBy: this.userData.ref,
                    classTeacherChangedByName: this.userData.firstName
                      ? this.userData.firstName
                      : null,
                  })
                  .then(() => {
                    this.newClassGroupTeacher = null;
                    this.currentClassGroupTeacher = null;
                    this.selectedClassGroupForTeacher = null;
                  });
              });
            } else {
              this.selectedClassGroupForTeacher.ref
                .update({
                  classTeacherRef: this.newClassGroupTeacher.ref,
                  TEACHER_NAME: this.newClassGroupTeacher.DISPLAY_NAME,
                  TEACHER_ID: this.newClassGroupTeacher.PERSON_ID,
                  classTeacherChanged: true,
                  classTeacherChangedBy: this.userData.ref,
                  classTeacherChangedByName: this.userData.firstName
                    ? this.userData.firstName
                    : null,
                })
                .then(() => {
                  this.newClassGroupTeacher = null;
                  this.currentClassGroupTeacher = null;
                  this.selectedClassGroupForTeacher = null;
                });
            }
          });
        }
      }
    },
    _showClassGroupTeacherChange(classGroup) {
      console.log(classGroup, classGroup.TEACHER_ID);
      this.selectedClassGroupForTeacher = classGroup;
      if (classGroup.TEACHER_ID) {
        this.userData.school.ref
          .collection("teachers")
          .doc(classGroup.TEACHER_ID.toString())
          .get()
          .then((doc) => {
            if (doc.exists) {
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              this.currentClassGroupTeacher = teacher;
              this.loading = false;
            }
            this.showClassGroupTeacherDialog =
              !this.showClassGroupTeacherDialog;
          });
      } else {
        this.showClassGroupTeacherDialog = !this.showClassGroupTeacherDialog;
      }

      // if (!this.departments) {
      //   this.loading = true;
      //   this.userData.school.ref
      //     .collection("teachers" ).doc("")
      //     .get()
      //     .then(() => {
      //       this.currentClassGroupTeacher =

      //       this.loading = false;
      //       this.showClassGroupTeacherDialog =
      //         !this.showClassGroupTeacherDialog;
      //     });
      // } else {
      //   this.showClassGroupTeacherDialog = !this.showClassGroupTeacherDialog;
      // }
    },
    _loadAllStudents() {
      if (
        this.userData.school._esisUserName &&
        this.userData.school._esisPword
      ) {
        this.$swal({
          title: "ESIS системээс БҮX сурагчдын мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.token) {
              this.departments.forEach((dep) => {
                dep.classGroups.forEach((classGroup) => {
                  if (!classGroup.readFromESISAt)
                    this._loadStudentsOfProg(classGroup, this.token);
                });
              });
            } else {
              this.loading = true;
              axios
                .post(
                  "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                  {
                    username: this.userData.school._esisUserName,
                    password: this.userData.school._esisPword,
                  }
                )
                .then((res) => {
                  this.token = res.data.token;
                  this.userData.school.ref
                    .update({ _es_TOKEN: this.token })
                    .then(() => {
                      this.loading = false;
                    });
                  // if (this.token) console.log("token read");
                  this.departments.forEach((dep) => {
                    dep.classGroups.forEach((classGroup) => {
                      if (!classGroup.readFromESISAt)
                        this._loadStudentsOfProg(classGroup, this.token);
                      // console.log(classGroup.fullName)
                    });
                  });
                });
            }
          }
        });
      }
    },
    _loadStudentsOfProg(prog, ktoken, warningDialog) {
      prog.loading = true;
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url:
              "https://hub.esis.edu.mn/svc/api/hub/group/student/list/" +
              prog.STUDENT_GROUP_ID,
            token: ktoken,
          }
        )
        .then(async (res) => {
          console.log(res.status);
          if (res.status === 200) {
            var batch = fb.db.batch();
            res.data.RESULT.forEach((item) => {
              item["classGroup-"+this.userData.school.currentYear] = prog.ref;
              item["classGroupName-"+this.userData.school.currentYear] = item.STUDENT_GROUP_NAME
                ? item.STUDENT_GROUP_NAME
                : null;
              item.role = "student";
              item.roleName = "Сурагч";
              item.readfrom_esis = true;
              item.email = item.EMAIL ? item.EMAIL : null;
              item.readfrom_esis = true;
              item.departmentIndex = parseInt(item.ACADEMIC_LEVEL);
              item.classGroupName = item.STUDENT_GROUP_NAME;
              item.deleted = false;
              item.created = new Date();
              item.lastName = item.LAST_NAME ? item.LAST_NAME : null;
              item.firstName = item.FIRST_NAME ? item.FIRST_NAME : null;

              batch.set(
                this.userData.school.ref
                  .collection("students-" + this.userData.school.currentYear)
                  .doc(item.PERSON_ID.toString()),
                item,
                { merge: true }
              );
            });

            batch.commit().then(() => {
              if (res.data.RESULT.length > 0) {
                console.log("updating... ", prog.ref.path);
                prog.ref.update({
                  numberOfStudents: res.data.RESULT.length,
                  readFromESISAt: new Date(),
                });

                prog.loading = false;
                if (warningDialog)
                  this.$swal.fire(
                    "Нийт " +
                      res.data.RESULT.length +
                      " сурагчийн мэдээлэл нэмэгдлээ"
                  );
              } else {
                if (warningDialog)
                  this.$swal.fire("Шинээр сурагч нэмэгдээгүй байна.");
              }
            });
          }
        });
    },
    _loadSingleClass(prog) {
      if (
        this.userData.school._esisUserName &&
        this.userData.school._esisPword
      ) {
        this.$swal({
          title: "ESIS системээс сурагчдын мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.token) {
              this._loadStudentsOfProg(prog, this.token, true);
            } else {
              axios
                .post(
                  "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                  {
                    username: this.userData.school._esisUserName,
                    password: this.userData.school._esisPword,
                  }
                )
                .then((res) => {
                  this.token = res.data.token;
                  this.userData.school.ref.update({ _es_TOKEN: this.token });
                  // if (this.token)
                  //   console.log("token read", prog.STUDENT_GROUP_ID);
                  this._loadStudentsOfProg(prog, this.token, true);
                });
            }
          }
        });
      }
    },
    _loadFromESIS() {
      if (
        this.userData.school._esisUserName &&
        this.userData.school._esisPword
      ) {
        this.$swal({
          title: "ESIS системээс мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: this.userData.school._esisUserName,
                  password: this.userData.school._esisPword,
                }
              )
              .then((res) => {
                this.token = res.data.token;
                this.loading = true;
                axios
                  .post(
                    "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                    {
                      url: "https://hub.esis.edu.mn/svc/api/hub/group/list",
                      token: this.token,
                    }
                  )
                  .then(async (res) => {
                    if (res.status === 200) {
                      this.loading = false;
                      this.userData.school.ref.update({
                        _esis_groupsInfo_updatedAt: new Date(),
                      });
                      var batch = fb.db.batch();
                      var counter = 0;
                      res.data.RESULT.forEach((item) => {
                        counter++;
                        batch.set(
                          this.userData.school.ref
                            .collection(
                              "departments-" + this.userData.school.currentYear
                            )
                            .doc("department-" + item.ACADEMIC_LEVEL),
                          {
                            createdAt: new Date(),
                            deleted: false,
                            index: parseInt(item.ACADEMIC_LEVEL),
                            name: item.ACADEMIC_LEVEL,
                            numberOfStudents: 0,
                            startYear: this.userData.school.currentYear,
                          },
                          { merge: true }
                        );

                        item.name = item.STUDENT_GROUP_NAME;
                        item.fullName = item.STUDENT_GROUP_NAME;
                        item.startYear = this.userData.school.currentYear;
                        item.departmentName = item.ACADEMIC_LEVEL;
                        item.createdAt = new Date();
                        item.deleted = false;

                        batch.set(
                          this.userData.school.ref
                            .collection(
                              "departments-" + this.userData.school.currentYear
                            )
                            .doc("department-" + item.ACADEMIC_LEVEL)
                            .collection("programs")
                            .doc(item.STUDENT_GROUP_ID.toString()),
                          item,
                          { merge: true }
                        );
                      });
                      batch.commit().then(async () => {
                        if (counter > 0) {
                          this.$swal.fire(
                            "Нийт " + counter + " бүлгийн мэдээлэл нэмэгдлээ"
                          );

                          res.data.RESULT.forEach(async (item) => {
                            console.log(
                              item["ACADEMIC_LEVEL"],
                              item["STUDENT_GROUP_NAME"],
                              item["TEACHER_NAME"],
                              item["TEACHER_ID"]
                            );
                            if (item["TEACHER_ID"]) {
                              this.loading = true;
                              var teacher = await this.userData.school.ref
                                .collection("teachers")
                                .doc(item["TEACHER_ID"].toString())
                                .get();
                              if (teacher.exists) {
                                var tmp = {};
                                // tmp[
                                //   "myClassRef-" + this.userData.school.currentYear
                                // ] = item.ref;
                                tmp[
                                  "myClassInfo-" +
                                    this.userData.school.currentYear
                                ] = item;

                                teacher.ref.update(tmp).then(() => {
                                  this.loading = false;
                                });
                              }
                            }
                          });
                        } else {
                          this.$swal.fire("Шинээр сурагч нэмэгдээгүй байна.");
                        }
                        this.userData.school.ref.update({
                          _es_groups_read: true,
                        });
                      });
                    } else {
                      alert("Мэдээлэл татаxад алдаа гарлаа.");
                      this.fb.db.collection("esis-connection-failed").doc({
                        createdAt: new Date(),
                        accessLink:
                          "https://hub.esis.edu.mn/svc/api/hub/teacher/list",
                        school: this.userData.school,
                      });
                    }
                  });
              });
          }
        });
      }
    },
    _getJoinedName(item) {
      var llname = "";
      if (item.lastName2) {
        llname = item.lastName2 + ". " + item.firstName;
      } else if (item.lastName && item.lastName.length > 0) {
        llname = item.lastName[0];
        llname = llname + ". " + item.firstName;
      } else llname = item.firstName;
      return llname;
    },

    _addTeacher(prog) {
      this.selectedProgram = prog;
      this.selectedProgram.teacher = {
        ref: prog.teacherRef,
        name2: prog.teacherName,
      };

      this.selectTeacherDialog = true;
    },
    async _saveTeacherToClass() {
      var ttt = {};
      // var teacherRef = this.selectedProgram.teacher.ref;
      var dataa = {};
      if (
        (this.selectedProgram.teacher &&
          this.selectedProgram.teacher.value == "noteacher") ||
        this.selectedProgram.teacher.ref == undefined
      ) {
        dataa = {
          teacherRef: null,
          teacherName: null,
          className: this.selectedProgram.className
            ? this.selectedProgram.className
            : null,
        };
        ttt["myClassRef-" + this.userData.school.currentYear] = null;

        if (this.selectedProgram.teacherRef) {
          this.selectedProgram.teacherRef.update(ttt).then(() => {
            this.selectedProgram.ref.update(dataa).then(() => {
              this._closeDialog();
            });
          });
        } else {
          this.selectedProgram.ref.update(dataa).then(() => {
            this._closeDialog();
          });
        }
      } else {
        console.log("dasdfsdfsdf", this.selectedProgram.teacher);
        var xx = await this.selectedProgram.teacher.ref.get();
        dataa = {
          teacherRef: this.selectedProgram.teacher.ref,
          teacherName:
            xx.data().lastName && xx.data().lastName.length > 0
              ? xx.data().lastName[0].toUpperCase() + ". " + xx.data().firstName
              : "" + xx.data().firstName,
          className: this.selectedProgram.className
            ? this.selectedProgram.className
            : null,
        };
        ttt["myClassRef-" + this.userData.school.currentYear] =
          this.selectedProgram.ref;

        this.selectedProgram.ref.update(dataa).then(() => {
          if (dataa.teacherRef) {
            dataa.teacherRef.update(ttt).then(() => {
              this._closeDialog();
            });
          } else {
            // dataa.teacherRef.update(ttt).then(() => {
            //   this._closeDialog();
            // });
            this._closeDialog();
          }
        });
      }
    },
    _closeDialog() {
      this.selectTeacherDialog = false;

      this.selectedProgram = null;
      this.selectedTeacher = null;
    },
    _calStudents() {
      this.departments.forEach((dep) => {
        dep.classgroups.forEach(async (progr) => {
          var studentRef = await this.school.ref
            .collection("students")
            .where("programs", "==", progr.ref)
            .get();
          if (!studentRef.empty) {
            progr.ref.update({ numberOfStudents: studentRef.size });
          } else {
            console.log(studentRef.size);
          }
        });
      });
    },
    async _setupp() {
      if (this.userData.role == "superadmin") this.editable = true;

      if (this.userData.school) {
        if (this.userData.role == "teacher") this.editable = false;
        this.school = this.userData.school;
        this.selectedYear = this.school.currentYear;
        this._getDepartments(this.userData.school.currentYear);
      } else {
        this.school = null;
        this.selectedYear = null;
      }

      if (this.userData.ppschool != null) {
        fb.db
          .collection(this.userData.ppschool + "/teachers")
          .where("role", "==", "teacher")
          .where("deleted", "==", false)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.teachers = [];
            this.teachers = [
              { name2: "<< Багшгүй >>", ref: null, value: "noteacher" },
            ];
            docs.docs.forEach((doc) => {
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              teacher.name2 = this._getJoinedName(teacher);
              this.teachers.push(teacher);
            });
          });
      }
    },
    _getDepartments(startYear) {
      this.userData.school.ref
        .collection("departments-" + startYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          this.classGroups = [];
          this.numberOfAllGroups = 0;
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  if (prog.fullName == undefined) {
                    prog.ref.update({ fullName: dep.name + prog.name });
                  }
                  if (
                    prog.numberOfStudents != undefined &&
                    prog.numberOfStudents != null
                  )
                    dep.numberOfStudents =
                      dep.numberOfStudents + prog.numberOfStudents;

                  prog.loading = false;
                  if (!prog.deleted) {
                    prog.index = ++this.numberOfAllGroups;
                    dep.classGroups.push(prog);
                  }
                });
              });
            this.departments.push(dep);
          });
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredTeachers() {
      if (this.teachers) {
        var list = this.teachers.map((teacher, index) => {
          if (teacher["FIRST_NAME"])
            teacher.name2 = index + 1 + ". " + teacher["FIRST_NAME"];
          return teacher;
        });
        return list;
      } else return [];
    },
    countStudents: function () {
      var count = 0;
      if (this.departments) {
        this.departments.forEach((dep) => {
          count = count + dep.numberOfStudents;
        });
      }

      return count;
    },
  },
  created() {
    if (this.userData.school) this._setupp();
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
